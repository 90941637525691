<template>
  <a-modal
    class="goods-get-example"
    :visible="visible"
    title="商品到样"
    :width="660"
    @cancel="handleCancel"
  >
    <div>
      <div class="goods-get-div">
        <div class="goods-div">
          <div class="btn-div">
            <a-input
              ref="ainput"
              placeholder="请输入到样快递单号"
              @keypress.enter.native="getGoodsList"
              v-model.trim="sample_express_number"
            />
            <a-button
              class="mgr-8"
              type="primary"
              size="small"
              @click="
                () => {
                  getGoodsList();
                  handleChange();
                }
              "
              >查询</a-button
            >
            <a-button
              @click="
                () => {
                  clearDataClick();
                  handleChange();
                }
              "
              >清空</a-button
            >
          </div>
          <a-table
            :key="tableKey"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
              getCheckboxProps: getCheckboxProps,
            }"
            :loading="listLoading"
            :pagination="false"
            :columns="listColumn"
            :data-source="list"
            bordered
            row-key="id"
          >
            <template slot="goods_img" slot-scope="record">
              <my-image
                :imgWidth="40"
                style="margin: 0px auto"
                v-if="record.item_id__pict_url || record.item_id__slide_images[0]"
                :imgUrl="record.item_id__pict_url || record.item_id__slide_images[0]"
              />
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <template slot="footer" class="footer-btns">
      <a-button
        :disabled="selectedRowKeys.length < 1"
        type="primary"
        :loading="posting"
        @click="handleModalOkClick"
        >打印</a-button
      >
      <a-button @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { DeleteOutlined } from '@ant-design/icons-vue';
import { getPrimaryGoodsList, postSampleSubmitGoods } from '@/service/merchant';
import printUtils from '@/utils/print';

export default defineComponent({
  props: {
    // 是否显示弹框
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DeleteOutlined,
  },
  setup(props, ctx) {
    const list = ref([]);
    const tableKey = ref(1);
    const posting = ref(false);
    const listLoading = ref(false);
    const sample_express_number = ref('');
    const selectedRowKeys = ref([]);
    const handleModalOkClick = async () => {
      if (list.value.length < 1) {
        ctx.root.$message.warning('请输入快递单号查询商品！');
        return;
      }
      posting.value = true;
      const {
        res: { success, message },
      } = await postSampleSubmitGoods({
        ids: selectedRowKeys.value,
      });
      posting.value = false;
      if (success) {
        ctx.root.$message.success('到样成功');
        ctx.emit('reload', false);
        printUtils.printSample(
          selectedRowKeys.value.map(id => {
            const item = list.value.find(sub => sub.id === id);
            return {
              code: item.id,
              dp: item.shop_name,
              xh: item.id,
              yj:
                item.item_id__exclusive_commission_rate ||
                item.item_id__exclusive_commission_rate === 0
                  ? item.item_id__exclusive_commission_rate / 100 + '%'
                  : '--',
              price: item.kuran_price || '--',
              tzyj:
                item.item_id__colonel_commission_rate || item.item_id__colonel_commission_rate === 0
                  ? item.item_id__colonel_commission_rate / 100 + '%'
                  : '--',
              ms: item.description || '',
            };
          }),
        );
        list.value = [];
        selectedRowKeys.value = [];
        tableKey.value = Math.random() * 10;
        if (location.href !== '') return;

        /*ctx.emit('reload');
        ctx.emit('closeClick');*/
      } else {
        ctx.root.$message.error(message || '操作失败');
      }
      /* ctx.emit('reload');
      ctx.emit('closeClick');*/
    };
    const listColumn = [
      {
        title: '序号',
        dataIndex: 'id',
        width: 80,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '图片',
        width: 80,
        align: 'center',
        scopedSlots: { customRender: 'goods_img' },
        /*customRender: (text)=> {
          return text || '----'
        },*/
      },
      {
        title: '店铺名称',
        dataIndex: 'shop_name',
        width: 180,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '商品名称',
        dataIndex: 'item_name',
        width: 180,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },
    ];
    const getGoodsList = async () => {
      if (!sample_express_number.value) {
        ctx.root.$message.warning('请输入快递单号！！！');
        return;
      }
      listLoading.value = true;
      const { err, res } = await getPrimaryGoodsList({
        sample_express_number: sample_express_number.value,
      });
      listLoading.value = false;
      if (!err) {
        if (res.success) {
          sample_express_number.value = '';
          const arr =
            res.data.results.filter(item => {
              const find = list.value.find(sub => sub.id === item.id);
              return !find;
            }) || [];
          if (arr && arr.length === 1) {
            setTimeout(() => {
              selectedRowKeys.value = selectedRowKeys.value.concat(...arr.map(item => item.id));
              tableKey.value = Math.random() * 10;
            }, 200);
          }

          list.value = arr.concat(...list.value);
        } else {
          ctx.root.$message.error(res.message || '数据获取失败');
        }
      }
    };

    const onSelectChange = selectedRows => {
      selectedRowKeys.value = selectedRows || [];
    };
    const getCheckboxProps = record => {
      return {
        //重点部分
        props: {
          defaultChecked: selectedRowKeys.value.indexOf(record.id) > -1 ? true : false, //defaultCheckedId里面是默认选中的id，判断是否含有这些id，有的那就选中，没有的就不选中
          id: record.id + '', //使得id的数据类型为string
        },
      };
    };
    const clearDataClick = () => {
      list.value = [];
      selectedRowKeys.value = [];
      sample_express_number.value = '';
    };
    return {
      clearDataClick,
      getCheckboxProps,
      onSelectChange,
      selectedRowKeys,
      tableKey,
      getGoodsList,
      listColumn,
      listLoading,
      sample_express_number,
      list,
      posting,
      handleModalOkClick,
    };
  },
  mounted() {
    this.handleChange();
  },
  methods: {
    // 关闭弹框
    handleCancel() {
      this.$emit('reload', false);
      this.$emit('closeClick');
    },
    handleChange() {
      this.$nextTick(() => {
        if (this.$refs.ainput) {
          this.$refs.ainput.focus();
        }
      });
    },
  },
});
</script>
<style lang="less">
.goods-get-example {
  .goods-div {
    width: 660px;
    .ant-btn-sm {
      font-size: 12px;
    }
    .ant-table-thead tr,
    .ant-table-thead {
      height: 32px !important;
    }
    .ant-table-wrapper {
      overflow: auto;
      max-height: 57vh;
    }
    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
      overflow-x: auto !important;
      overflow-y: auto !important;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 5px 10px !important;
      font-size: 12px;
    }
    .btn-div {
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      input {
        width: 480px;
        margin-right: 18px;
        font-size: 12px;
        height: 28px;
      }
      .mgr-8 {
        margin-right: 8px;
      }
      button {
        height: 28px;
        line-height: 26px;
        padding: 0 12px;
      }
    }
  }
  .ant-input,
  .ant-btn,
  .ant-select {
    font-size: 12px;
    height: 28px;
    line-height: 26px;
    display: inline-block;
  }

  .ant-modal {
    margin-top: 0;
  }
  .ant-modal-body {
    min-height: 250px;
    max-height: calc(100px + 65vh);
    padding-bottom: 16px;
  }
  .goods-get-div {
    display: flex;
    .label {
      width: 70px;
      line-height: 32px;
    }
  }

  .ant-modal-footer {
    text-align: center;
    border-top: none;
    button + button {
      margin-left: 12px;
    }
  }
}
</style>
