<template>
  <a-modal
    class="clear-goods-example"
    :visible="visible"
    title="商品清库"
    :width="660"
    @cancel="handleCancel"
  >
    <div>
      <div class="clear-goods-div">
        <div class="goods-div">
          <div class="btn-div">
            <a-input
              ref="ainput"
              placeholder="请输入序号"
              @keypress.enter.native="getGoodsList"
              v-model.trim="id_number"
            />
            <a-button
              class="mgr-8"
              type="primary"
              size="small"
              @click="
                () => {
                  getGoodsList();
                  handleChange();
                }
              "
              >查询</a-button
            >
            <a-button
              @click="
                () => {
                  clearDataClick();
                  handleChange();
                }
              "
              >清空</a-button
            >
          </div>
          <a-table
            :loading="listLoading"
            :pagination="false"
            :columns="listColumn"
            :data-source="list"
            bordered
            row-key="id"
          >
            <template slot="goods_img" slot-scope="record">
              <my-image
                :imgWidth="40"
                style="margin: 0px auto"
                v-if="record.item_id__pict_url || record.item_id__slide_images[0]"
                :imgUrl="record.item_id__pict_url || record.item_id__slide_images[0]"
              />
            </template>
            <template slot="operation" slot-scope="record">
              <a-icon type="delete" @click="handleDeleteGoods(record)" />
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <template slot="footer" class="footer-btns">
      <a-button
        :disabled="list.length < 1"
        type="primary"
        :loading="posting"
        @click="handleClearData"
        >清库</a-button
      >
      <a-button @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { DeleteOutlined } from '@ant-design/icons-vue';
import { getPrimaryGoodsList, postClearGoods } from '@/service/merchant';
import { Modal } from 'ant-design-vue';
export default defineComponent({
  props: {
    // 是否显示弹框
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DeleteOutlined,
  },
  setup(props, ctx) {
    const list = ref([]);
    const posting = ref(false);
    const listLoading = ref(false);
    const id_number = ref('');
    const handleClearData = () => {
      Modal.confirm({
        title: () => ' 商品清库',
        content: () => '请确定是否清库？',
        onOk() {
          handleModalOkClick();
        },
        icon: false,
        closable: true,
        class: 'test',
      });
    };
    const handleModalOkClick = async () => {
      posting.value = true;
      const {
        res: { success, message },
      } = await postClearGoods({
        ids: list.value.map(item => item.id),
      });
      posting.value = false;
      if (success) {
        ctx.root.$message.success('清库成功！');
        ctx.emit('reload', false);
        list.value = [];
        /*ctx.emit('reload');
        ctx.emit('closeClick');*/
      } else {
        ctx.root.$message.error(message || '操作失败');
      }
      /* ctx.emit('reload');
      ctx.emit('closeClick');*/
    };
    const listColumn = [
      {
        title: '序号',
        dataIndex: 'id',
        width: 80,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '图片',
        width: 80,
        align: 'center',
        scopedSlots: { customRender: 'goods_img' },
        /*customRender: (text)=> {
          return text || '----'
        },*/
      },
      {
        title: '店铺名称',
        dataIndex: 'shop_name',
        width: 180,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '商品名称',
        dataIndex: 'item_name',
        width: 180,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '操作',
        align: 'center',
        width: 80,
        scopedSlots: { customRender: 'operation' },
      },
    ];
    const getGoodsList = async () => {
      if (!id_number.value) {
        ctx.root.$message.warning('请输入序号！！！');
        return;
      }
      listLoading.value = true;
      const { err, res } = await getPrimaryGoodsList({
        id: id_number.value,
        is_clear: 0,
      });
      listLoading.value = false;
      if (!err) {
        if (res.success) {
          id_number.value = '';
          const arr =
            res.data.results.filter(item => {
              const find = list.value.find(sub => sub.id === item.id);
              return !find;
            }) || [];
          list.value = arr.concat(...list.value);
        } else {
          ctx.root.$message.error(res.message || '数据获取失败');
        }
      }
    };
    const handleDeleteGoods = record => {
      list.value = list.value.filter(item => item.id != record.id);
    };
    const clearDataClick = () => {
      id_number.value = '';
      list.value = [];
    };
    return {
      clearDataClick,
      handleDeleteGoods,
      getGoodsList,
      listColumn,
      listLoading,
      id_number,
      list,
      posting,
      handleClearData,
    };
  },
  mounted() {
    this.handleChange();
  },
  methods: {
    // 关闭弹框
    handleCancel() {
      this.$emit('reload', false);
      this.$emit('closeClick');
    },
    handleChange() {
      this.$nextTick(() => {
        if (this.$refs.ainput) {
          this.$refs.ainput.focus();
        }
      });
    },
  },
});
</script>
<style lang="less">
.clear-goods-example {
  .anticon {
    font-size: 16px;
    color: #999999;
    &:hover {
      color: #e83741;
    }
  }
  .goods-div {
    width: 660px;
    .ant-btn-sm {
      font-size: 12px;
    }
    .ant-table-thead tr,
    .ant-table-thead {
      height: 32px !important;
    }
    .ant-table-wrapper {
      overflow: auto;
      max-height: 57vh;
    }
    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
      overflow-x: auto !important;
      overflow-y: auto !important;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 5px 10px !important;
      font-size: 12px;
    }
    .btn-div {
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      input {
        width: 480px;
        margin-right: 18px;
        font-size: 12px;
        height: 28px;
      }
      .mgr-8 {
        margin-right: 8px;
      }
      button {
        height: 28px;
        line-height: 26px;
        padding: 0 12px;
      }
    }
  }
  .ant-input,
  .ant-btn,
  .ant-select {
    font-size: 12px;
    height: 28px;
    line-height: 26px;
    display: inline-block;
  }

  .ant-modal {
    margin-top: 0;
  }
  .ant-modal-body {
    min-height: 250px;
    max-height: calc(100px + 65vh);
    padding-bottom: 16px;
  }
  .clear-goods-div {
    display: flex;
    .label {
      width: 70px;
      line-height: 32px;
    }
  }

  .ant-modal-footer {
    text-align: center;
    border-top: none;
    button + button {
      margin-left: 12px;
    }
  }
}
</style>
