<template>
  <div class="shop-product-list-page">
    <kr-card :padding="[0, 0, 12, 0]" @rect:update="onTopCardRectUpdate">
      <a-form layout="inline" :form="queryParams">
        <div class="filter-condition-form">
          <a-form-item label="单号：">
            <a-input
              v-model.trim="queryParams.sample_express_number"
              allowClear
              placeholder="请输入寄样单号"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="序号：">
            <a-input
              v-model.trim="queryParams.id"
              allowClear
              placeholder="请输入序号"
              style="width: 180px"
              @keypress.enter.native="reload"
              @change="queryParams.id = queryParams.id.replace(/^[0]+/, '')"
            />
          </a-form-item>
          <a-form-item label="名称：">
            <a-input
              v-model.trim="queryParams.item_name"
              allowClear
              placeholder="请输入商品名称"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="商品ID：" class="shop-id-item">
            <a-input
              v-model.trim="queryParams.item_id"
              allowClear
              placeholder="请输入商品ID"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="店铺：">
            <a-input
              v-model.trim="queryParams.shop_name"
              allowClear
              placeholder="请输入店铺名称"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <!--  <a-form-item label="公司：">
              <a-input
                v-model.trim="queryParams.supplier_name"
                allowClear
                placeholder="请输入公司名称"
                style="width: 180px"
                @keypress.enter.native="reload"
              />
            </a-form-item>-->
          <!--          <a-form-item v-if="currentTab === 4" label="状态：">
            <a-select
              v-model="queryParams.check_status"
              placeholder="审核状态"
              allowClear
              style="width: 180px"
            >
              <a-select-option
                v-for="(item, key) in checkStatusOptions"
                :key="key"
                :value="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </a-form-item>-->
          <a-form-item label="招商：">
            <a-input
              v-model.trim="queryParams.contact_username"
              allowClear
              placeholder="请输入招商人员"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item>
            <div class="btn-lines">
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="showAllQuery">高级筛选</a-button>
              <a-button @click="reset">重置</a-button>
              <a-button @click="handleGoodsGetData">到样</a-button>
              <a-button @click="handleClearData">清库</a-button>
              <a-button :loading="exportLoading" @click="exportListClick">导出</a-button>
            </div>
          </a-form-item>
        </div>
      </a-form>
    </kr-card>
    <!-- [列表] -->
    <a-table
      class="tablecl"
      :class="list.length * 56 < tableScrollY ? 'less-table' : ''"
      :height="500"
      :scroll="{ x: 1000, y: tableScrollY }"
      :rowKey="record => record.id"
      :columns="goodsColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="locale"
      @change="paginationHandler"
      style="word-break: break-all"
    >
      <template slot="goods_img" slot-scope="record">
        <my-image
          :imgWidth="40"
          style="margin: 0px auto"
          v-if="record.item_id__pict_url || record.item_id__slide_images[0]"
          :imgUrl="record.item_id__pict_url || record.item_id__slide_images[0]"
        />
      </template>
      <template slot="item_id__commission_rate" slot-scope="record">
        {{
          record.item_id__commission_rate || record.item_id__commission_rate === 0
            ? record.item_id__commission_rate / 100 + '%'
            : '--'
        }}
      </template>
      <template slot="item_id__exclusive_commission_rate" slot-scope="record">
        {{
          record.item_id__exclusive_commission_rate ||
          record.item_id__exclusive_commission_rate === 0
            ? record.item_id__exclusive_commission_rate / 100 + '%'
            : '--'
        }}
      </template>
      <template slot="item_id__colonel_commission_rate" slot-scope="record">
        {{
          record.item_id__colonel_commission_rate || record.item_id__colonel_commission_rate === 0
            ? record.item_id__colonel_commission_rate / 100 + '%'
            : '--'
        }}
      </template>
      <template slot="check_status" slot-scope="record">
        <span v-if="record.first_check_status === 0">待审核</span>
        <span v-if="record.first_check_status === 1" style="color: green">已报名</span>
        <span v-if="record.first_check_status === 2" style="color: red">未通过</span>
      </template>
      <template slot="is_recycle" slot-scope="record">
        <a-tag v-if="record.is_recycle === 0" color="green">否</a-tag>
        <a-tag v-if="record.is_recycle === 1" color="red">是</a-tag>
      </template>
      <template slot="sample_express_number" slot-scope="record">
        <div v-if="record.sample_express_number">
          <div class="number">{{ record.sample_express_number }}</div>
          <div class="number">{{ record.sample_express_company }}</div>
        </div>
        <span v-else-if="record.check_status === 1"> 未上传 </span>
        <span v-else> -- </span>
      </template>
      <template slot="card_img" slot-scope="record">
        <my-image
          :imgWidth="40"
          style="margin: 0px auto"
          v-if="getImgUrl(record, 1)"
          :imgUrl="getImgUrl(record, 1)"
        />
      </template>
      <template slot="price_img" slot-scope="record">
        <my-image
          :imgWidth="40"
          style="margin: 0px auto"
          v-if="getImgUrl(record, 2)"
          :imgUrl="getImgUrl(record, 2)"
        />
      </template>
      <template slot="other_img" slot-scope="record">
        <my-image
          :imgWidth="40"
          style="margin: 0px auto"
          v-if="getImgUrl(record, 3)"
          :imgUrl="getImgUrl(record, 3)"
        />
      </template>
      <template slot="operation" slot-scope="record">
        <a-button
          v-if="record.first_check_status === 0"
          type="primary"
          size="small"
          @click="handleApprovalGoods(record)"
          >选品</a-button
        >
        <a-button
          v-if="record.first_check_status !== 0"
          type="primary"
          size="small"
          class="look"
          @click="handleApprovalGoods(record, 1)"
          >查看</a-button
        >
        <!--        <a-button v-if="record.first_check_status===1 && record.sample_express_number"  size="small"  class="mr-10" @click="handleUploadGoods(record)">样品图片</a-button>-->
      </template>
    </a-table>
    <div v-if="createGoodsDrawerVisiable">
      <a-drawer
        title="商品初审"
        placement="right"
        width="800"
        @close="closeClick"
        :visible="createGoodsDrawerVisiable"
      >
        <audit-detail
          v-on:closeClick="closeClick"
          v-on:reload="reload"
          :readOnly="readOnly"
          :detailData="goodsRow"
          :step="1"
        />
      </a-drawer>
    </div>
    <div v-if="showAllVisiable">
      <a-drawer
        title="高级筛选"
        class="select-div"
        placement="right"
        width="346"
        @close="closeClick"
        :visible="showAllVisiable"
      >
        <select-query-form
          v-on:closeClick="closeClick"
          v-on:reset="reset"
          v-on:reload="reload"
          :query-form="queryParams"
          :step="1"
          :list-loading="listLoading"
        />
      </a-drawer>
    </div>
    <div v-if="isModalVisible">
      <upload
        :visible="isModalVisible"
        :detailData="goodsRow"
        v-on:closeClick="closeClick"
        v-on:reload="reload"
        @close="isModalVisible = false"
      />
    </div>
    <div v-if="isGoodsGetVisible">
      <goods-get
        :visible="isGoodsGetVisible"
        v-on:closeClick="closeClick"
        v-on:reload="reload"
        @close="isGoodsGetVisible = false"
      />
    </div>
    <div v-if="isClearGoodsVisible">
      <clear-goods
        :visible="isClearGoodsVisible"
        v-on:closeClick="closeClick"
        v-on:reload="reload"
        @close="isClearGoodsVisible = false"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { exportMerchantPrimaryGoodsList, getPrimaryGoodsList } from '@/service/merchant';
import auditDetail from '@/pages/admin/merchant/dialog/auditDetail';
import selectQueryForm from '@/pages/admin/merchant/dialog/selectQueryForm';
import upload from '@/pages/admin/merchant/dialog/upload';
import goodsGet from '@/pages/admin/merchant/dialog/goodsGet';
import clearGoods from '@/pages/admin/merchant/dialog/clearGoods';
import { Empty } from 'ant-design-vue';

export default defineComponent({
  name: 'KrProductPrimary',
  components: {
    auditDetail,
    upload,
    selectQueryForm,
    goodsGet,
    clearGoods,
  },
  data() {
    return {
      locale: {
        emptyText: <a-empty description="暂无商品" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      },
    };
  },
  methods: {
    tabChangeCallback(tab) {
      this.currentTab = tab;
      this.queryParams.page = 1;
      this.reset();
    },
    paginationHandler(params) {
      if (params.pageSize !== this.queryParams.page_size) {
        this.queryParams.page = 1;
        this.pagination.current = 1;
        this.queryParams.page_size = params.pageSize;
      } else {
        this.pagination.current = params.current;
        this.queryParams.page = params.current;
      }
      this.reload(false);
    },
  },
  setup(_, ctx) {
    const createGoodsDrawerVisiable = ref(false);
    const goodsRow = ref({});
    const readOnly = ref(false);
    const showAllVisiable = ref(false);
    const showAllQuery = () => {
      showAllVisiable.value = true;
    };
    const handleApprovalGoods = (row, type = 0) => {
      createGoodsDrawerVisiable.value = true;
      readOnly.value = type === 1;
      goodsRow.value = row;
    };
    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY = ref(windowHeight - 280);
    const onTopCardRectUpdate = rect => {
      tableScrollY.value = windowHeight - 190 - rect.height;
    };
    // 当前选中Tab
    const currentTab = ref(4);

    // 商品列表
    const goodsColumns = [
      {
        title: '序号',
        width: 100,
        align: 'left',
        dataIndex: 'id',
      },
      {
        title: '图片',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'goods_img' },
      },
      {
        title: '商品ID',
        dataIndex: 'item_id',
        align: 'left',
        ellipsis: true,
        width: 190,
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '店铺名称',
        dataIndex: 'shop_name',
        width: 200,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },
      /* {
         title: '店铺类型',
         dataIndex: 'brand_type',
         width: 100,
         ellipsis: true,
         align: 'center',
         customRender: text => {
           return text === 1 ? '品牌方' : text === 2 ? '代理商' : text === 3 ? '黑标' : '--';
         },
       },
       {
         title: '一级分类',
         dataIndex: 'merchant_category',
         width: 120,
         ellipsis: true,
         align: 'center',
         customRender: text => {
           return text || '--';
         },
       },*/
      {
        title: '商品名称',
        dataIndex: 'item_name',
        width: 200,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },

      /*{
        title: '手卡',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'card_img' },
      },
      {
        title: '比价图',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'price_img' },
      },
      {
        title: '其它资料',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'other_img' },
      },*/
      {
        title: '销售价',
        dataIndex: 'kuran_price',
        align: 'right',
        ellipsis: true,
        width: 100,
        customRender: (text, record) => {
          let price = record?.kuran_price;
          if (price !== null && price !== undefined && price !== '') {
            price = '￥' + price;
          } else {
            price = '--';
          }
          return price;
        },
      },
      /* {
        title: '公开佣金',
        scopedSlots: { customRender: 'item_id__commission_rate' },
        width: 100,
        align: 'right',
      },*/
      {
        title: '达人佣金',
        scopedSlots: { customRender: 'item_id__exclusive_commission_rate' },
        width: 100,
        align: 'right',
      },
      {
        title: '团长佣金',
        scopedSlots: { customRender: 'item_id__colonel_commission_rate' },
        width: 100,
        align: 'right',
      },
      {
        title: '价格/机制',
        dataIndex: 'description',
        align: 'left',
        width: 100,
        ellipsis: true,
        customRender: text => {
          return text || '--';
        },
      },
      /*{
        title: '赠品工具',
        dataIndex: 'giveaway_tool',
        align: 'center',
        width: 100,
        customRender: text => {
          return text === 1 ? '已开启' : '未开启';
        },
      },

      {
        title: '赠品价格/机制',
        dataIndex: 'giveaway_description',
        align: 'left',
        width: 130,
        ellipsis: true,
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '多拍链接',
        dataIndex: 'is_minimum_per_order',
        align: 'center',
        width: 100,
        ellipsis: true,
        customRender: text => {
          return text === 1 ? '已设置' : '未设置';
        },
      },
      {
        title: '库存',
        dataIndex: 'stock',
        width: 100,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },

      {
        title: '店铺口碑分',
        dataIndex: 'item_id__merchant_experience_score',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '店铺体验分',
        dataIndex: 'item_id__service_score',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '发货周期',
        dataIndex: 'delivery_cycle',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '运费险',
        dataIndex: 'freight_policy',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text === 1 ? '有' : '无';
        },
      },*/
      {
        title: '对接招商',
        dataIndex: 'contact__username',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      /*{
        title: '公司名称',
        dataIndex: 'supplier',
        width: 200,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },*/
      {
        title: '状态',
        scopedSlots: { customRender: 'check_status' },
        align: 'center',
        width: 110,
      },
      {
        title: '到样日期',
        dataIndex: 'sample_date',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '寄样单号',
        scopedSlots: { customRender: 'sample_express_number' },
        align: 'left',
        ellipsis: true,
        width: 260,
      },
      /*  {
          title: '是否退样',
          scopedSlots: { customRender: 'is_recycle' },
          width: 100,
          align: 'center',
        },
       */
      {
        title: '清库',
        dataIndex: 'is_clear',
        width: 70,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text === 1 ? '是' : text === 0 ? '否' : '';
        },
      },
      {
        title: '操作',
        align: 'center',
        fixed: 'right',
        width: 100,
        scopedSlots: { customRender: 'operation' },
      },
    ];
    const getImgUrl = (row, type = 1) => {
      if (row.attachment && row.attachment.length > 0) {
        let url = '';
        row.attachment.map(item => {
          if (item.type === type) {
            url = item.url || '';
          }
        });
        return url;
      } else {
        return '';
      }
    };
    const list = ref([]);
    const pagination = ref({
      size: 'small',
      current: 1,
      pageSize: 20,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      showTotal: total =>
        `共 ${total} 条记录 第 ${pagination.value.current}/${Math.ceil(
          total / pagination.value.pageSize,
        )} 页`,
      showQuickJumper: true,
      showSizeChanger: true,
      onShowSizeChange: (current, pageSize) => (pagination.value.pageSize = pageSize),
    });
    const queryParams = ref({
      page: 1,
      page_size: 20,
      item_name: '',
      id: '',
      item_id: '',
      shop_name: '',
      supplier_name: '',
      check_status: undefined,
      sample_express_number: '',
      exclusive_commission_rate: ['', ''],
      item_id__colonel_commission_rate: ['', ''],
      item_id__commission_rate: ['', ''],
      merchant_experience_score: ['', ''],
      service_score: ['', ''],
      contact_username: '',
      merchant_category: '',
      brand_type: '',
      is_recycle: undefined,
      is_clear: undefined,
      sample_date: '',
    });
    const reset = () => {
      queryParams.value = {
        page: 1,
        page_size: queryParams.value.page_size,
        item_name: '',
        id: '',
        item_id: '',
        shop_name: '',
        supplier_name: '',
        check_status: undefined,
        sample_express_number: '',
        exclusive_commission_rate: ['', ''],
        item_id__colonel_commission_rate: ['', ''],
        item_id__commission_rate: ['', ''],
        merchant_experience_score: ['', ''],
        service_score: ['', ''],
        contact_username: '',
        merchant_category: '',
        brand_type: '',
        is_recycle: undefined,
        is_clear: undefined,
        sample_date: '',
      };
      reload();
    };

    const listLoading = ref(false);
    const getGoodsList = async () => {
      /*const { ...rest } = queryParams.value;
      const payload = {
        ...rest,
        check_status: currentTab.value === 4 ? queryParams.value.check_status : currentTab.value,
      };*/

      listLoading.value = true;
      const exclusive_commission_rate_le =
        queryParams.value.exclusive_commission_rate[1] ||
        queryParams.value.exclusive_commission_rate[1] === 0
          ? Number(queryParams.value.exclusive_commission_rate[1] || 0) * 100
          : '';
      const exclusive_commission_rate_ge =
        queryParams.value.exclusive_commission_rate[0] ||
        queryParams.value.exclusive_commission_rate[0] === 0
          ? Number(queryParams.value.exclusive_commission_rate[0] || 0) * 100
          : '';
      const item_id__colonel_commission_rate_le =
        queryParams.value.item_id__colonel_commission_rate[1] ||
        queryParams.value.item_id__colonel_commission_rate[1] === 0
          ? Number(queryParams.value.item_id__colonel_commission_rate[1] || 0) * 100
          : '';
      const item_id__colonel_commission_rate_ge =
        queryParams.value.item_id__colonel_commission_rate[0] ||
        queryParams.value.item_id__colonel_commission_rate[0] === 0
          ? Number(queryParams.value.item_id__colonel_commission_rate[0] || 0) * 100
          : '';
      const item_id__commission_rate_le =
        queryParams.value.item_id__commission_rate[1] ||
        queryParams.value.item_id__commission_rate[1] === 0
          ? Number(queryParams.value.item_id__commission_rate[1] || 0) * 100
          : '';
      const item_id__commission_rate_ge =
        queryParams.value.item_id__commission_rate[0] ||
        queryParams.value.item_id__commission_rate[0] === 0
          ? Number(queryParams.value.item_id__commission_rate[0] || 0) * 100
          : '';
      const { err, res } = await getPrimaryGoodsList({
        page: queryParams.value.page,
        page_size: queryParams.value.page_size,
        item_name: queryParams.value.item_name,
        id: queryParams.value.id,
        item_id: queryParams.value.item_id,
        shop_name: queryParams.value.shop_name,
        supplier_name: queryParams.value.supplier_name,
        check_status: queryParams.value.check_status,
        sample_express_number: queryParams.value.sample_express_number,
        exclusive_commission_rate_le: exclusive_commission_rate_le,
        exclusive_commission_rate_ge: exclusive_commission_rate_ge,
        item_id__colonel_commission_rate_le: item_id__colonel_commission_rate_le,
        item_id__colonel_commission_rate_ge: item_id__colonel_commission_rate_ge,
        item_id__commission_rate_le: item_id__commission_rate_le,
        item_id__commission_rate_ge: item_id__commission_rate_ge,
        merchant_experience_score_ge: queryParams.value.merchant_experience_score[0],
        merchant_experience_score_le: queryParams.value.merchant_experience_score[1],
        service_score_ge: queryParams.value.service_score[0],
        service_score_le: queryParams.value.service_score[1],
        contact_username: queryParams.value.contact_username,
        merchant_category: queryParams.value.merchant_category,
        brand_type: queryParams.value.brand_type,
        is_recycle: queryParams.value.is_recycle,
        is_clear: queryParams.value.is_clear,
        sample_date: queryParams.value.sample_date,
      });
      listLoading.value = false;
      if (!err) {
        if (res.success) {
          queryParams.value.id = '';
          const one_pagination = { ...pagination.value };
          list.value = res.data.results.map((item, index) => {
            return { id: index, ...item };
          });
          console.log('---', list.value.length);
          one_pagination.total = res.data.count;
          pagination.value = one_pagination;
        }
      }
    };

    const reload = async (clean = true, formData = null) => {
      if (clean) {
        pagination.value.current = 1;
        queryParams.value.page = 1;
      }
      if (formData) {
        queryParams.value = formData;
      }
      await getGoodsList();
    };

    const checkStatusOptions = [
      /* {
        label: '全部',
        value: undefined,
      },*/
      {
        label: '待审核',
        value: 0,
      },
      {
        label: '已报名',
        value: 1,
      },
      {
        label: '未通过',
        value: 2,
      },
    ];

    const isModalVisible = ref(false);
    const isGoodsGetVisible = ref(false);
    const isClearGoodsVisible = ref(false);
    const closeClick = () => {
      isModalVisible.value = false;
      createGoodsDrawerVisiable.value = false;
      showAllVisiable.value = false;
      isGoodsGetVisible.value = false;
      isClearGoodsVisible.value = false;
    };

    const handleUploadGoods = row => {
      isModalVisible.value = true;
      goodsRow.value = row;
    };
    // 导出列表处理
    const exportLoading = ref(false);
    const exportListClick = async () => {
      exportLoading.value = true;
      const exclusive_commission_rate_le =
        queryParams.value.exclusive_commission_rate[1] ||
        queryParams.value.exclusive_commission_rate[1] === 0
          ? Number(queryParams.value.exclusive_commission_rate[1] || 0) * 100
          : '';
      const exclusive_commission_rate_ge =
        queryParams.value.exclusive_commission_rate[0] ||
        queryParams.value.exclusive_commission_rate[0] === 0
          ? Number(queryParams.value.exclusive_commission_rate[0] || 0) * 100
          : '';
      const item_id__colonel_commission_rate_le =
        queryParams.value.item_id__colonel_commission_rate[1] ||
        queryParams.value.item_id__colonel_commission_rate[1] === 0
          ? Number(queryParams.value.item_id__colonel_commission_rate[1] || 0) * 100
          : '';
      const item_id__colonel_commission_rate_ge =
        queryParams.value.item_id__colonel_commission_rate[0] ||
        queryParams.value.item_id__colonel_commission_rate[0] === 0
          ? Number(queryParams.value.item_id__colonel_commission_rate[0] || 0) * 100
          : '';
      const item_id__commission_rate_le =
        queryParams.value.item_id__commission_rate[1] ||
        queryParams.value.item_id__commission_rate[1] === 0
          ? Number(queryParams.value.item_id__commission_rate[1] || 0) * 100
          : '';
      const item_id__commission_rate_ge =
        queryParams.value.item_id__commission_rate[0] ||
        queryParams.value.item_id__commission_rate[0] === 0
          ? Number(queryParams.value.item_id__commission_rate[0] || 0) * 100
          : '';
      const { res } = await exportMerchantPrimaryGoodsList({
        page: 1,
        item_name: queryParams.value.item_name,
        id: queryParams.value.id,
        item_id: queryParams.value.item_id,
        shop_name: queryParams.value.shop_name,
        supplier_name: queryParams.value.supplier_name,
        check_status: queryParams.value.check_status,
        sample_express_number: queryParams.value.sample_express_number,
        exclusive_commission_rate_le: exclusive_commission_rate_le,
        exclusive_commission_rate_ge: exclusive_commission_rate_ge,
        item_id__colonel_commission_rate_le: item_id__colonel_commission_rate_le,
        item_id__colonel_commission_rate_ge: item_id__colonel_commission_rate_ge,
        item_id__commission_rate_le: item_id__commission_rate_le,
        item_id__commission_rate_ge: item_id__commission_rate_ge,
        merchant_experience_score_ge: queryParams.value.merchant_experience_score[0],
        merchant_experience_score_le: queryParams.value.merchant_experience_score[1],
        service_score_ge: queryParams.value.service_score[0],
        service_score_le: queryParams.value.service_score[1],
        contact_username: queryParams.value.contact_username,
        merchant_category: queryParams.value.merchant_category,
        brand_type: queryParams.value.brand_type,
        is_recycle: queryParams.value.is_recycle,
        is_clear: queryParams.value.is_clear,
        sample_date: queryParams.value.sample_date,
      });
      exportLoading.value = false;
      if (res && res.success) {
        location.href = res.data.detail_url;
        ctx.root.$message.success('导出成功');
      } else {
        ctx.root.$message.error('导出失败');
      }
    };
    const handleClearData = () => {
      isClearGoodsVisible.value = true;
    };
    const handleGoodsGetData = () => {
      isGoodsGetVisible.value = true;
    };
    return {
      isClearGoodsVisible,
      isGoodsGetVisible,
      handleGoodsGetData,
      handleClearData,
      reset,
      getImgUrl,
      showAllQuery,
      showAllVisiable,
      exportLoading,
      readOnly,
      exportListClick,
      handleUploadGoods,
      isModalVisible,
      closeClick,
      onTopCardRectUpdate,
      goodsRow,
      handleApprovalGoods,
      createGoodsDrawerVisiable,
      pagination,
      listLoading,
      goodsColumns,
      checkStatusOptions,
      tableScrollY,
      list,
      queryParams,
      reload,
      currentTab,
    };
  },
  created() {
    this.reload();
  },
});
</script>

<style lang="less">
.shop-product-list-page {
  display: flex;
  flex-direction: column;
  .mr-10 {
    margin-right: 10px;
  }

  .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  .ant-table-tbody > tr,
  .ant-table-tbody > tr > td {
    height: 56px !important;
    .number {
      line-height: 20px;
      margin-bottom: 0;
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  /deep/ .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  /deep/.ant-table-tbody > tr {
    height: 56px !important;
    .number {
      line-height: 20px;
      margin-bottom: 0;
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  /deep/.ant-table-tbody > tr > td {
    height: 56px !important;
    .number {
      line-height: 20px;
      margin-bottom: 0;
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
<style lang="less" scoped>
/deep/ .ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}
.ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}
</style>
