var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"goods-get-example",attrs:{"visible":_vm.visible,"title":"商品到样","width":660},on:{"cancel":_vm.handleCancel}},[_c('div',[_c('div',{staticClass:"goods-get-div"},[_c('div',{staticClass:"goods-div"},[_c('div',{staticClass:"btn-div"},[_c('a-input',{ref:"ainput",attrs:{"placeholder":"请输入到样快递单号"},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getGoodsList.apply(null, arguments)}},model:{value:(_vm.sample_express_number),callback:function ($$v) {_vm.sample_express_number=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"sample_express_number"}}),_c('a-button',{staticClass:"mgr-8",attrs:{"type":"primary","size":"small"},on:{"click":function () {
                _vm.getGoodsList();
                _vm.handleChange();
              }}},[_vm._v("查询")]),_c('a-button',{on:{"click":function () {
                _vm.clearDataClick();
                _vm.handleChange();
              }}},[_vm._v("清空")])],1),_c('a-table',{key:_vm.tableKey,attrs:{"row-selection":{
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            getCheckboxProps: _vm.getCheckboxProps,
          },"loading":_vm.listLoading,"pagination":false,"columns":_vm.listColumn,"data-source":_vm.list,"bordered":"","row-key":"id"},scopedSlots:_vm._u([{key:"goods_img",fn:function(record){return [(record.item_id__pict_url || record.item_id__slide_images[0])?_c('my-image',{staticStyle:{"margin":"0px auto"},attrs:{"imgWidth":40,"imgUrl":record.item_id__pict_url || record.item_id__slide_images[0]}}):_vm._e()]}}])})],1)])]),_c('template',{staticClass:"footer-btns",slot:"footer"},[_c('a-button',{attrs:{"disabled":_vm.selectedRowKeys.length < 1,"type":"primary","loading":_vm.posting},on:{"click":_vm.handleModalOkClick}},[_vm._v("打印")]),_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v("关闭")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }